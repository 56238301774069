import type { CompanyAdvId } from '@/shared/model/types/Company';

export const METRICS_BY_TABLE = 'clicks,imps,payoutRub,revenueRub,placementId';
export const METRICS_BY_CHART = 'clicks,imps,date,payoutRub,revenueRub,placementId';
export const GROUP_BY_DETAIL_TABLE = 'campaignId,paramId';
export const GROUP_BY_DETAIL_CHART = 'date,campaignId,paramId';
export const GROUP_BY_CHART = 'date,campaignId';
export const GROUP_BY_TABLE = 'campaignId';

export const ADJUSTMENT_METRICS_BY_TABLE = ['imps', 'clicks', 'revenueRub'];
export const ADJUSTMENT_GROUP_BY_TABLE = ['campaignAdId', 'placementAdId'];

export const ADJUSTMENT_METRICS_BY_CHART = ['date', 'campaignAdId', 'imps', 'clicks', 'companyId', 'advertiserId'];
export const ADJUSTMENT_GROUP_BY_CHART = ['date', 'campaignAdId'];

export const ADJUSTMENT_METRICS_BY_DETAIL_TABLE = ['campaignAdId', 'paramId', 'imps', 'clicks', 'revenueRub'];
export const ADJUSTMENT_GROUP_BY_DETAIL_TABLE = ['paramId', 'placementAdId', 'campaignAdId'];
export const ADJUSTMENT_METRICS_BY_DETAIL_CHART = ['date', 'campaignAdId', 'imps', 'clicks', 'paramId'];
export const ADJUSTMENT_GROUP_BY_DETAIL_CHART = ['date', 'campaignAdId', 'paramId'];

/** Константы для отчетов по статистике с корректировками **/
export const REPORT_EXCEL_METRICS_BY_CHART = ['date', 'campaignAdId', 'imps', 'clicks', 'revenueRub'];
export const REPORT_EXCEL_GROUP_BY_CHART = ['date', 'placementAdId', 'campaignAdId'];
export const REPORT_EXCEL_METRICS_BY_TABLE = ['campaignAdId', 'imps', 'clicks', 'revenueRub'];
export const REPORT_EXCEL_GROUP_BY_TABLE = ['placementAdId', 'campaignAdId'];
export const REPORT_EXCEL_DETAIL_METRICS_BY_CHART = ['date', 'paramId', 'campaignAdId', 'imps', 'clicks', 'revenueRub'];
export const REPORT_EXCEL_DETAIL_GROUP_BY_CHART = ['date', 'paramId', 'placementAdId', 'campaignAdId'];
export const REPORT_EXCEL_DETAIL_METRICS_BY_TABLE = ['paramId', 'campaignAdId', 'imps', 'clicks', 'revenueRub'];
export const REPORT_EXCEL_DETAIL_GROUP_BY_TABLE = ['paramId', 'placementAdId', 'campaignAdId'];
export interface ChartRawByTable {
    campaignId: CompanyAdvId;
    clicks: number;
    ctr: number;
    date: string;
    imps: number;
    payoutRub: number;
    revenueRub: number;
}

export interface ChartRawByDetail {
    campaignId: CompanyAdvId;
    clicks: number;
    date: string;
    ctr: number;
    imps: number;
    payoutRub: number;
    revenueRub: number;
    paramId: string;
}

export interface ReportRawByTable {
    campaignId: CompanyAdvId;
    clicks: number;
    imps: number;
    ctr: number;
    payoutRub: number;
    revenueRub: number;
}

export interface ReportRawByDetail {
    campaignId: CompanyAdvId;
    clicks: number;
    imps: number;
    ctr: number;
    payoutRub: number;
    revenueRub: number;
    paramId: string;
}

export interface ChartAdjustedByTable {
    campaignId: CompanyAdvId;
    imps: number;
    ctr: number;
    clicks: number;
    date: string;
}

export interface ChartAdjustedByDetail {
    campaignId: CompanyAdvId;
    imps: number;
    ctr: number;
    clicks: number;
    date: string;
    paramId: string;
}

export interface ReportAdjustedByTable {
    campaignId: CompanyAdvId;
    imps: number;
    ctr: number;
    clicks: number;
    revenueRub: number;
    placementAdId: number;
}

export interface ReportAdjustedByDetail {
    campaignId: CompanyAdvId;
    imps: number;
    ctr: number;
    clicks: number;
    revenueRub: number;
    placementAdId: number;
    paramId: string;
}

export enum ReportExcelType {
    TABLE_VIEW = 'table-view',
    CHART_VIEW = 'chart-view'
}
